/* Portfolio Section */
.portfolio {
    padding: 80px 20px;
    background-color: #f4f4f4;
    text-align: center;
}

.portfolio h2 {
    margin-bottom: 40px;
    font-size: 36px;
}

.portfolio-filters {
    margin-bottom: 20px;
}

.portfolio-filters button {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.portfolio-filters button.active {
    background-color: #333;
    color: #fff;
}

.portfolio-filters button:hover {
    background-color: #555;
    color: #fff;
}

.my-masonry-grid {
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
}

.portfolio-item {
    margin-bottom: 30px;
}

.portfolio-item img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
    margin-bottom: 10px;
}

.portfolio-item h3 {
    font-size: 18px;
    color: #333;
}
