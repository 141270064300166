/* Global Styles */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    scroll-behavior: smooth;
}

/* Navbar */
.navbar {
    position: fixed;
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    z-index: 1000;
}

.nav-links {
    display: flex;
    list-style: none;
}

.nav-links li {
    margin: 0 20px;
}

.nav-links a {
    color: white;
    text-decoration: none;
    font-size: 18px;
}

/* Hero Section */
.hero {
    height: 100vh;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: relative;
}

.hero-content {
    max-width: 600px;
    z-index: 1;
}

.linkedin-icon {
    color: white;
    font-size: 36px;
    margin-top: 20px;
    transition: color 0.3s ease;
}

.linkedin-icon:hover {
    color: #0077B5;
}

.arrow {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    color: black;
    border-radius: 50%;
    padding: 10px;
    font-size: 24px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.arrow:hover {
    background-color: #f0f0f0;
    color: black;
}

/* About Section */
.about {
    padding: 100px 20px;
    background-color: #f4f4f4;
    text-align: center;
}

/* Resume Section */
.resume {
    padding: 80px 20px;
    background-color: #ffffff;
}

.resume h2 {
    margin-bottom: 40px;
    font-size: 36px;
    text-align: center;
}

.resume-subsection {
    display: flex;
    align-items: flex-start;
    margin-bottom: 50px;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.section-title {
    flex: 1;
    font-size: 28px;
    color: #333;
    margin-right: 40px;
    text-align: right;
}

.section-content {
    flex: 3;
}

.resume-item {
    margin-bottom: 30px;
}

.resume-item h4 {
    font-size: 22px;
    margin-bottom: 5px;
    color: #555;
}

.resume-item p {
    margin: 5px 0;
    color: #777;
}

/* Footer */
footer {
    padding: 20px;
    background-color: #333;
    color: white;
    text-align: center;
}

.arrow-up {
    display: block;
    margin: 20px auto;
    color: white;
    font-size: 24px;
    cursor: pointer;
}

.typing-effect {
    font-size: 36px;
    color: white;
    text-align: center;
    margin-bottom: 20px;
}

.hero-content h1, .Typed {
    font-size: 36px;
    color: white;
    text-align: center;
    margin-bottom: 20px;
}
